import React from "react";
import Styles from "./styles";
import ButtonTextInputForm from "../../components/ButtonTextInputForm";
import NavLink from "../../components/NavLink";
import StoreLink from "../../components/StoreLink";
import Button from "../../components/Button";
import { navLinksData, storeLinksData } from "./data";
import Logo from "../../components/Logo";

const { Wrapper, NavContent } = Styles;

const Navigation = () => {
    return (
        <Wrapper>
            <NavContent>
                <Logo />
            </NavContent>
        </Wrapper>
    );
};

export default Navigation;
