import React, { PropsWithChildren, useEffect } from "react";
import Styles from "./styles";
import Navigation from "../Navigation";

const { Wrapper } = Styles;

const Layout = ({ children }: PropsWithChildren<{}>) => {
    useEffect(() => {
        const p = new Promise((resolve, reject) => {
            const a = fetch("https://reqres.in/api/users/6")
                .then((res) => res.json())
                .then(({ data }) => {
                    return data;
                });
            resolve(a);
        });
        p.then((data) => console.log(data));
    }, []);
    return (
        <Wrapper id="fb-root">
            <Navigation />
            {children}
        </Wrapper>
    );
};

export default Layout;
