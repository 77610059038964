import styled from "styled-components";
import { myTheme } from "../../MyTheme";

const {
    color: { gray },
} = myTheme;

const Styles = {
    Wrapper: styled.div`
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: ${gray};
        min-height: 100vh;
    `,
};

export default Styles;
