import styled from "styled-components";
import { myTheme } from "../../MyTheme";

const {
    color: { black, white },
} = myTheme;

const Styles = {
    Wrapper: styled.div`
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        background-color: ${black};
        height: 85px;
        border-bottom: 1px solid ${white};
    `,
    NavContent: styled.div`
        width: 1200px;
        height: 100%;
        display: flex;
        justify-content: space-between;
    `,
};

export default Styles;
